import { Link } from "react-router-dom";
import styles from "./home.module.css";

const Benefits = () => {
  return (
    <div className={`${styles.benefits} container`}>
      <div
        data-aos="fade-right"
        data-aos-offset="300"
        data-aos-easing="ease-in-sine"
      >
        <Link
          to="/explore"
          style={{
            display: "flex",
            color: "#6a6a6a",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h4>01</h4>
          <p style={{ paddingLeft: "10px" }}>Fitness Training</p>
        </Link>
      </div>

      <div
        data-aos="fade-right"
        data-aos-offset="300"
        data-aos-easing="ease-in-sine"
      >
        <Link
          to="/blog"
          style={{
            display: "flex",
            color: "#6a6a6a",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h4>02</h4>
          <p style={{ paddingLeft: "10px" }}>Blogs</p>
        </Link>
      </div>
      <div
        data-aos="fade-right"
        data-aos-offset="300"
        data-aos-easing="ease-in-sine"
      >
        <Link
          to="/courses"
          style={{
            display: "flex",
            color: "#6a6a6a",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h4>03</h4>
          <p style={{ paddingLeft: "10px" }}>Courses</p>
        </Link>
      </div>

      <div
        data-aos="fade-right"
        data-aos-offset="300"
        data-aos-easing="ease-in-sine"
      >
        {/* <i className="fa-brands fa-google"></i> */}
        <div>
          <Link
            to="/explore"
            style={{
              display: "flex",
              color: "#6a6a6a",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h4>04</h4>
            <p style={{ paddingLeft: "10px" }}>Fitness Centers</p>
          </Link>
          {/* <div> */}
          {/* <i className="fa-solid fa-star"></i>
            <i className="fa-solid fa-star"></i>
            <i className="fa-solid fa-star"></i>
            <i className="fa-solid fa-star"></i>
            <i className="fa-solid fa-star"></i> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default Benefits;
