import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllJobsAction } from "../../store/actions/job";
import { Link } from "react-router-dom";
import styles from "./job.module.css";
const JobList = () => {
  const jobs = useSelector((state) => state.job.jobs);
  const dispatch = useDispatch();
  const onGetAllJobs = useCallback(() => {
    dispatch(getAllJobsAction());
  }, [dispatch]);

  useEffect(() => {
    onGetAllJobs();
  }, [onGetAllJobs]);
  return (
    <div className="container" style={{ marginTop: "200px" }}>
      <div className="col-lg-12 col-md-12-col-12">
        <div>
          <h3>Join Our Mission, No Matter Where You Are!</h3>
          <p style={{ marginTop: "20px" }}>
            <strong>Ready2Gym</strong> is committed to transforming the fitness
            industry by bridging the gap between local gyms, health centers, and
            yoga studios and their communities. We're growing and inviting
            enthusiastic individuals to join our team as Sales Executives,
            Telecallers, Field Executives, Team Leaders, or Office Boys. You can
            also join us as a freelancer with attractive payouts and
            revenue-sharing opportunities. Help us expand our platform, generate
            leads, and uncover new paths to success—while you enjoy the benefits
            of your hard work.
          </p>
          <br />
          <h3 style={{ marginTop: "20px", paddingBottom: "30px" }}>
            {" "}
            Take the Next Step with Us:
          </h3>
          <div>
            {jobs &&
              jobs.map((ele) => {
                return (
                  <Link
                    className="mt-4"
                    to={`/fitness-center-detail/${ele._id}`}
                  >
                    <div className="row">
                      <div
                        className="col-lg-12 col-md-12 col-12"
                        style={{
                          paddingBottom: "40px",
                        }}
                      >
                        <div
                          className={styles.info}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingBottom: "30px",
                          }}
                        >
                          <div>
                            <span style={{ color: "#1d2229" }}>
                              {ele?.title}
                            </span>
                            <p
                              style={{
                                color: "#1d2229",
                                textTransform: "capitalize",
                              }}
                            >
                              {" "}
                              Category: {ele?.category}
                            </p>
                          </div>
                          <div style={{ paddingRight: "15px" }}>
                            <Link
                              to={`/job-detail/${ele._id}`}
                              className={styles.btnApply}
                              style={{
                                backgroundColor: "rgba(106, 115, 250)",
                                color: "#fff",
                                padding: "10px 18px",
                                borderRadius: "6px",
                                marginTop: "40px",
                                width: "35%",
                              }}
                            >
                              Apply
                            </Link>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </Link>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobList;
