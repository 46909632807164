import authReducer from "./auth";
import { combineReducers } from "redux";
import organisationReducer from "./organisation";
import fitnessCenterReducer from "./fitnessCenter";
import trainerReducer from "./trainer";
import holidayReducer from "./holiday";
import dietPlanReducer from "./dietplan";
import financeHeadReducer from "./financehead";
import financeReducer from "./finance";
import eventReducer from "./event";
import courseReducer from "./course";
import workoutReducer from "./workout";
import jobReducer from "./job";
import postReducer from "./post";
const rootReducers = combineReducers({
  auth: authReducer,
  organisation: organisationReducer,
  fitnesscenter: fitnessCenterReducer,
  trainer: trainerReducer,
  holiday: holidayReducer,
  dietplan: dietPlanReducer,
  finance: financeReducer,
  financeHead: financeHeadReducer,
  event: eventReducer,
  course: courseReducer,
  workout: workoutReducer,
  job: jobReducer,
  post: postReducer,
});

export default rootReducers;
