import { SET_JOBS } from "./types";
import { getAllJobsService } from "../../services/jobs";

export const getAllJobsAction = () => async (dispatch) => {
  try {
    const res = await getAllJobsService();
    dispatch({
      type: SET_JOBS,
      payload: res.data.jobs,
    });
  } catch (e) {
    console.log(e);
  }
};
