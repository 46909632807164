import { SET_DIET_PLAN } from "../actions/types";

const initialState = {
  dietplans: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_DIET_PLAN:
      return {
        ...state,
        dietplans: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
}
