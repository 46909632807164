import React from "react";
import RootStack from "./RootStack";
// import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import { ThemeContext } from "./context/ThemeContext";
import { Toaster } from "react-hot-toast";
const App = () => {
  return (
    <div>
      {/* <ThemeContext> */}
      <Provider store={store}>
        <Toaster />
        {/* <BrowserRouter basename="/"> */}
        <RootStack />
        {/* </BrowserRouter> */}
      </Provider>
      {/* </ThemeContext> */}
    </div>
  );
};

export default App;
