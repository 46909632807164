import { useState } from "react";
import styles from "./register.module.css";
import { validateField } from "../../util";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate, useParams, Link } from "react-router-dom";
// import { addEnquiryService } from "../../services/enquiry";
const SignUp = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [oader, setLoader] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [fitnessCenterId, setFitnesCenterId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const params = useParams();

  //   const formHandler = async (e) => {
  //     e.preventDefault();
  //     let validate = true;

  //     if (name.trim() === "") {
  //       validateField("name", "Name field is required");
  //       validate = false;
  //     }
  //     if (email.trim() === "") {
  //       validateField("email", "Email field is required");
  //       validate = false;
  //     }
  //     if (message.trim() === "") {
  //       validateField("message", "Message field is required");
  //       validate = false;
  //     }
  //     if (message.trim() === "") {
  //       validateField("message", "Message field is required");
  //       validate = false;
  //     }

  //     if (!validate) {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Ooos!",
  //         text: "Please fill required fields!",
  //       });
  //       return;
  //     }
  //     if (validate) {
  //       try {
  //         setLoader(true);
  //         const res = await addEnquiryService({
  //           name,
  //           phone,
  //           email,
  //           subject,
  //           message,
  //           fitnessCenterId: params._id,
  //         });

  //         if (res.data.status) {
  //           setLoader(false);

  //           Swal.fire({
  //             icon: "success",
  //             title: "Good job!",
  //             text: "Enquiry Sent Successfully!",
  //           });
  //           setName("");
  //           setEmail("");
  //           setPhone("");
  //           setSubject("");
  //           setMessage("");

  //           navigate("/");
  //         }
  //       } catch (err) {
  //         setLoader(false);
  //         console.log(err);
  //       }
  //     }
  //   };
  // const formHandler = (e) => {
  //   e.preventDefault();

  //   if (!name || !email || !subject) {
  //     setErrorMessage("Please fill in all fields.");
  //     return;
  //   } else {
  //     setName("");
  //     setEmail("");
  //     setPhone("");
  //     setSubject("");
  //     setMessage("");
  //     setErrorMessage("Send Successfully");
  //   }
  // };

  return (
    <>
      <div
        className="col-lg-12 col-12"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="col-lg-6 col-12">Hello</div>
      </div>
      <div className="col-lg-6 col-12">
        <form>
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">
              Email address
            </label>
            <input
              type="email"
              class="form-control"
              id="exampleFormControlInput1"
              placeholder="name@example.com"
            />
          </div>
          <div class="mb-3">
            <label for="exampleFormControlTextarea1" class="form-label">
              Example textarea
            </label>
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
            ></textarea>
          </div>
        </form>
      </div>
    </>
  );
};

export default SignUp;
