import { SET_FITNESS_CENTERS } from "./types";
import { getAllFitnessCenterService } from "../../services/fitness-center";

export const getAllFitnessCenters = (search) => async (dispatch) => {
  try {
    let query = ``;
    if (search) {
      query = `?search=${search}`;
    }
    const res = await getAllFitnessCenterService(query);
    dispatch({
      type: SET_FITNESS_CENTERS,
      payload: res.data.fitnesscentersweb,
    });
  } catch (e) {
    console.log(e);
  }
};
