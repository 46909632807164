import { articlesNewsData } from "../../data/data";
import styles from "./home.module.css";
import React, { useEffect, useCallback } from "react";
import { getAllPostsAction } from "../../store/actions/post";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
const ArticlesNews = () => {
  const posts = useSelector((state) => state.post.posts);
  const dispatch = useDispatch();
  const onGetPostsList = useCallback(() => {
    dispatch(getAllPostsAction());
  }, [dispatch]);

  useEffect(() => {
    onGetPostsList();
  }, [onGetPostsList]);
  return (
    <>
      <div style={{ display: "block", padding: "25px" }}>
        <h2>Articles</h2>
        <div
          className={`${styles["articles-news"]} container`}
          style={{ display: "flex" }}
        >
          {posts &&
            posts.map((ele) => {
              return (
                <Link
                  key={ele.id}
                  to={`/blog-detail/${ele?._id}`}
                  data-aos="flip-up"
                >
                  <div
                    key={ele.id}
                    data-aos="zoom-out-right"
                    style={{
                      marginRight: "20px",
                      backgroundImage: `url(${ele.bannerImage})`,
                    }}
                  >
                    {/* <img src={ele.bannerImage} alt="" /> */}
                    <span>{moment(ele.created).format("DD-MM-YYYY")}</span>
                    {/* <span>{ele.title}</span> */}
                    <h3>{ele.title}</h3>
                    <p>{ele.subTitle}</p>
                    {/* <Link
                    className={`${styles["button"]}`}
                    key={ele.id}
                    to={`/blog-detail/${ele?._id}`}
                    data-aos="flip-up"
                    style={{
                      padding: "15px",
                      backgroundColor: "#fff",
                      colr: "#000",
                    }}
                  >
                    Read More
                  </Link> */}
                  </div>
                </Link>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default ArticlesNews;
