import { SET_TRAINERS } from "../actions/types";

const initialState = {
  isAuthenticated: false,
  token: null,
  tokenExpired: false,
  trainers: null,
};

export default function Trainers(state = initialState, action) {
  switch (action.type) {
    case SET_TRAINERS:
      return {
        ...state,
        trainers: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
}
