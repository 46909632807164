import publicReq, { privateReq, imageUpload } from "../AxiosConfig";

// add and update course services
export const addCourseService = (data) => privateReq.post("/add-course", data);

export const updateCourseService = (data) =>
  privateReq.post("/update-course", data);

export const getAllCourseService = (data) => privateReq.get("/get-course-list");

export const getCourseDetailService = (data) =>
  privateReq.get("/get-course/by-id/" + data);

export const deleteCourseService = (data) =>
  privateReq.delete("/delete-course" + data);
