import {
  SET_FINANCE_ENTRIES,
  SET_SEARCH_FINANCE_ENTRIES,
  SET_PAYMENT_REQUEST,
  SET_PURCHASE_REQUEST,
} from "../actions/types";

const initialState = {
  isAuthenticated: false,
  token: null,
  tokenExpired: false,
  financeEntries: [],
  paymentRequests: [],
  purchaseRequests: [],
  searchedFinanceEntries: null,
};

export default function finaceReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FINANCE_ENTRIES:
      return {
        ...state,
        financeEntries: action.payload,
      };
    case SET_SEARCH_FINANCE_ENTRIES:
      return {
        ...state,
        searchedFinanceEntries: action.payload,
      };
    case SET_PAYMENT_REQUEST:
      return {
        ...state,
        paymentRequests: action.payload,
      };
    case SET_PURCHASE_REQUEST:
      return {
        ...state,
        purchaseRequests: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
