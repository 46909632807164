import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "./trainers.module.css";
import { getAllFitnessCenters } from "../../store/actions/fitnesscenter";

const ExploreCenters = () => {
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const fitnesscenters = useSelector(
    (state) => state.fitnesscenter.fitnesscenters
  );

  useEffect(() => {
    if (search.length > 2) {
      dispatch(getAllFitnessCenters(search));
    } else if (search.length == 0) {
      dispatch(getAllFitnessCenters());
    }
  }, [search]);

  return (
    <div className={`${styles["our-trainers"]} container sections-padding`}>
      <div>
        {/* <p className="paragraph">Our Trainers</p> */}
        <h2>Fitness Centers Near You</h2>
      </div>
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          marginBottom: "15px",
          padding: "10px, 20px",
        }}
      >
        <input
          style={{
            padding: "12px 120px",
            borderRadius: "10px",
            textAlign: "start",
          }}
          type="text"
          placeholder="Search by city or name"
          value={search}
          name="search"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className={styles["trainers-boxes"]}>
        {fitnesscenters &&
          fitnesscenters.map((ele) => {
            return (
              <Link className="mt-4" to={`/fitness-center-detail/${ele._id}`}>
                <div key={ele.id} data-aos="fade-right">
                  <img
                    src={ele.profileImage}
                    alt="trainer"
                    style={{
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px",
                    }}
                  />
                  <div
                    className={styles.info}
                    style={{
                      borderBottomLeftRadius: "8px",
                      borderBottomRightRadius: "8px",
                    }}
                  >
                    <div>
                      <h4>{ele.fitnessCenterName}</h4>
                      <h4>{ele.fitnessCenterCity}</h4>
                      {/* <p>
                        <i className="fa-solid fa-star"></i>/5
                      </p> */}
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>Since : {ele.fitnessCenterSince}</p>
                      </div>
                      {/* <div style={{ display: "flex", alignItems: "center" }}>
                        <p>City : {ele.fitnessCenterCity}</p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
      </div>
    </div>
  );
};

export default ExploreCenters;
