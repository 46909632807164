import { useNavigate } from "react-router";
import React, { useState, useCallback, useRef } from "react";
import styles from "./home.module.css";
import Carousel from "react-bootstrap/Carousel";
import { IMAGES } from "../../assets/image.jsx";
const heraImages = [
  {
    image: IMAGES.caroimage1,
    // title: "Why Should You Consider Taking an Education Course?",
    // like: "400",
    // proferssor: "Jack Ronan",
  },
  {
    image: IMAGES.caroimage2,
    // title: "Education Courses: A Guide to Unlocking Your Potential",
    // like: "320",
    // proferssor: "Jimmy Morris",
  },
  {
    image: IMAGES.caroimage3,
    // title: "A Comprehensive Guide to Taking an Education Course",
    // like: "250",
    // proferssor: "Konne Backfield",
  },
  {
    image: IMAGES.caroimage4,
    // title: "When Is the Best Time to Take an Education Course?",
    // like: "350",
    // proferssor: "Nashid Martines",
  },
];
const HeroSection = () => {
  const navigate = useNavigate();

  return (
    <>
      <Carousel interval={2000} fade={true} controls={true}>
        {heraImages.map((data, ind) => (
          <Carousel.Item>
            <img className="d-block w-100" src={data.image} alt="Third slide" />
            <Carousel.Caption>
              {" "}
              <div>
                <button onClick={() => navigate("/explore")}>
                  Start Today
                </button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </>

    // <div className={styles["hero-section"]} data-aos="zoom-out-down">
    //   <h1 className="main-heading">
    //     Keep Your Body
    //     <br />
    //     Fit & Strong
    //   </h1>
    //   <div>
    //     <button onClick={() => navigate("/explore")}>Start Today</button>

    //   </div>
    // </div>
  );
};

export default HeroSection;
