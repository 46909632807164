import React, { useEffect, useState } from "react";
import { getJobDetailService, createJobApplication } from "../../services/jobs";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Col } from "react-bootstrap";
import { validateField } from "../../util";
import toast from "react-hot-toast";
const JobDetail = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loader, setLoader] = useState(false);
  const [resumeImage, setResumeImage] = useState("");
  const [resumeImageUrl, setResumeImageUrl] = useState("");

  const [jobId, setJobId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [jobDetail, setJobDetail] = useState(null);
  const params = useParams();

  const onGetJobDetail = async () => {
    try {
      const filterQuery = params.id;
      let res = await getJobDetailService(filterQuery);
      if (res.data.job) {
        setJobDetail(res.data.job);
        setLoader(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    onGetJobDetail();
  }, []);
  const handleImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setResumeImage(img);
      setResumeImageUrl(URL.createObjectURL(img));
    }
  };
  const formHandler = async (e) => {
    e.preventDefault();
    // let validate = true;

    // if (name.trim() === "") {
    //   validateField("name", "Name field is required");
    //   validate = false;
    // }
    // if (phone.trim() === "") {
    //   validateField("phone", "Phone field is required");
    //   validate = false;
    // }
    // if (email.trim() === "") {
    //   validateField("email", "Email field is required");
    //   validate = false;
    // }
    // if (!validate) {
    //   toast.error("Please fill required fields");
    //   return;
    // }

    try {
      setLoader(true);
      let fd = new FormData();
      let data = {
        name,
        phone,
        email,
        resumeImage,
        jobId: params.id,
      };

      for (let key in data) {
        fd.append(key, data[key]);
      }
      const res = await createJobApplication(fd);

      if (res.data.success) {
        setLoader(false);
        Swal.fire({
          icon: "success",
          title: "Good job!",
          text: "Applied Successfully!",
        });
        setLoader(false);
        navigate("/");
      }
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };
  return (
    <div className="container" style={{ marginTop: "200px" }}>
      <div className="row">
        {" "}
        <div className="col-lg-6 col-md-6 col-12">
          <h3 style={{ textTransform: "capitalize", marginBlock: "15px" }}>
            {" "}
            {jobDetail?.category.label}
          </h3>
          <h2 style={{ textTransform: "capitalize", marginBlock: "15px" }}>
            {jobDetail?.title}
          </h2>
          <p
            style={{
              textTransform: "capitalize",
              marginBlock: "15px",
              paddingBottom: "30px",
              whiteSpace: "pre-line",
            }}
          >
            {jobDetail?.desc}
          </p>
        </div>
        <div className="col-lg-6 col-md-6 col-12">
          <div data-aos="fade-up">
            <h2>Apply for this job</h2>
            {/* <p>Your email & phone address will not be published *</p> */}
            <form onSubmit={formHandler}>
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">
                  Enter Your Full Name*
                </label>
                <input
                  className="form-control fallback w-100"
                  type="text"
                  placeholder="Full Name"
                  value={name}
                  name="name"
                  required
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">
                  Enter Phone*
                </label>
                <input
                  className="form-control fallback w-100"
                  type="text"
                  placeholder="Phone"
                  value={phone}
                  required
                  phone="phone"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">
                  Enter Email*
                </label>
                <input
                  className="form-control fallback w-100"
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={email}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div
                className="form-control fallback w-100"
                style={{
                  display: "block",
                }}
              >
                <label className="form-label" htmlFor="education">
                  Upload Resume
                </label>
                <input
                  type="file"
                  className="form-control"
                  data-default-file=""
                  onChange={handleImage}
                  required
                />
              </div>
              {/* <Link
                className="mt-4"
                to={`/course-detail/${ele?._id}`}
                style={{
                  backgroundColor: "rgba(106, 115, 250)",
                  color: "#fff",
                  padding: "10px 18px",
                  borderRadius: "6px",
                  marginTop: "40px",
                }}
              >
                Read More
              </Link> */}
              <div style={{ paddingTop: "20px" }}>
                <button
                  style={{
                    backgroundColor: "rgba(106, 115, 250)",
                    color: "#fff",
                    padding: "10px 18px",
                    borderRadius: "6px",
                    marginTop: "40px",
                    width: "50%",
                  }}
                  className="form-control"
                  type="submit"
                >
                  Submit
                </button>
              </div>
              <p>{errorMessage}</p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDetail;
