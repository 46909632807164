import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { headerLinksData } from "../../data/data";

import styles from "./header.module.css";
import logo from "../../assets/logodark.png";

const Header = () => {
  const navigate = useNavigate();

  const [active, setActive] = useState(false);

  const menuHandler = () => {
    setActive(!active);
  };

  return (
    <header className="hero-section">
      <div className={`${styles.header_content} container`}>
        <div className={styles.logo_holder} onClick={() => navigate("/")}>
          <div className={styles.image_holder}>
            <img src={logo} alt="Logo" style={{ height: 175, width: 175 }} />
          </div>
        </div>
        <ul className={active ? `${styles.activeList}` : ""}>
          {headerLinksData.map((link) => {
            return (
              <li key={link.id}>
                <Link to={link.to}>{link.title}</Link>
              </li>
            );
          })}
          <li>
            {/* <button
              style={{ marginRight: "10px" }}
              onClick={() => navigate("/register")}
            > */}
            <Link
              to={"https://manage.ready2gym.com/register"}
              target="_blank"
              className={`${styles.join_button}join_button`}
              style={{
                borderColor: "#ccc",
                borderWidth: 1,
                borderRadius: "5px",
              }}
            >
              Join As Fitness Center
            </Link>
            {/* </button> */}
          </li>
          {/* <li>
            <button onClick={() => navigate("/courses")}>Courses</button>
          </li> */}
        </ul>
        <div className={styles.menu} onClick={() => menuHandler()}>
          <i className={`fas ${active ? "fa-times" : "fa-bars"} open-list`}></i>
        </div>
        <div
          className={`${styles.menu_overlay} ${
            active ? `${styles.active_overlay}` : ""
          }`}
          onClick={() => menuHandler()}
        ></div>
      </div>
    </header>
  );
};

export default Header;
