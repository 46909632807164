import {
  SET_FINANCE_CATEGORY,
  SET_SEARCH_FINANCE_CATEGORIES,
} from "../actions/types";

const initialState = {
  isAuthenticated: false,
  token: null,
  tokenExpired: false,
  financeCategories: null,
  searchedFinanceCategories: null,
};

export default function finaceReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FINANCE_CATEGORY:
      return {
        ...state,
        financeCategories: action.payload,
      };
    case SET_SEARCH_FINANCE_CATEGORIES:
      return {
        ...state,
        searchedFinanceCategories: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
