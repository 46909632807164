import React, { useState, useEffect } from "react";
import { getCourseDetailService } from "../../services/course";
import { Link, useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import styles from "../blogSingle/blogSingle.module.css";
import moment from "moment";
const CourseDetail = () => {
  const [courseDetail, setCourseDetail] = useState(null);
  const params = useParams();
  const [loader, setLoader] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const onGetCourseDetail = async () => {
    try {
      const filterQuery = params.id;
      let res = await getCourseDetailService(filterQuery);
      if (res.data.course) {
        setCourseDetail(res.data.course);
        setLoader(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    onGetCourseDetail();
  }, []);
  return (
    <>
      <div
        className={`${styles["hero-section"]} container margin-sections `}
        data-aos="zoom-out-down"
      >
        <h2>{courseDetail?.name}</h2>
        <h5>By {courseDetail?.fitnessCenterId?.fitnessCenterName}</h5>
        <div>
          {/* <h4>Fitness</h4> */}
          <div className={styles.small_image}>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                padding: "5px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <img
                  src={courseDetail?.courseImage}
                  alt=""
                  style={{ width: "50px", height: "50px" }}
                />
              </div>
              <div style={{ marginLeft: "10px" }}>
                <div>
                  <p>
                    {courseDetail?.instructoreName +
                      " - " +
                      courseDetail?.experience}{" "}
                    Experience
                  </p>
                  <p style={{ marginTop: "-15px" }}>
                    Posted on:{" "}
                    {moment(courseDetail?.created).format("DD-MM-YYYY")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src={courseDetail?.courseImage}
          alt="Program to Improve Your Weakness"
        />
        <div>
          <div>
            <p>Starts From :</p>
          </div>
          <div>
            <p>{moment(courseDetail?.startFrom).format("DD-MM-YYYY")}</p>
          </div>
        </div>
        <div>
          <div>
            <p>Max. Students</p>
          </div>
          <div>
            <p>{courseDetail?.maximumStudents}</p>
          </div>
        </div>
        <div>
          <div>
            <p>Bathces</p>
          </div>
          <div>
            <p>{courseDetail?.totalBatches}</p>
          </div>
        </div>
        <div>
          <div>
            <p>Duration</p>
          </div>
          <div>
            <p>{courseDetail?.duration}</p>
          </div>
        </div>
        <div>
          <div>
            <p style={{ marginBottom: 30, textDecoration: "underline" }}>
              Details of Course
            </p>
            <p>{courseDetail?.detail}</p>
          </div>
        </div>
        <ReactPlayer
          url={courseDetail?.youTubeUrl}
          playIcon={true}
          controls={true}
          width={"1100px"}
        />
        <div style={{ display: "block" }}>
          <p style={{ marginBottom: 10, textDecoration: "underline" }}>
            Disclaimer:
          </p>
          <p>{courseDetail?.disclaimer}</p>
        </div>
        {/* {user ? ( */}
        <Link
          className="mt-4"
          to={`/enquiry/${courseDetail?.id}`}
          state={{
            fitnessCenterName: courseDetail?.fitnessCenterId?.fitnessCenterName,
            courseName: courseDetail?.name,
          }}
          // to={`/enquiry${courseDetail?._id}`}
          style={{
            backgroundColor: "rgba(106, 115, 250)",
            color: "#fff",
            padding: "15px 38px",
            borderRadius: "6px",
            marginTop: "40px",
          }}
        >
          Join Now
        </Link>
        {/* ) : (
          <Link
            className="mt-4"
            to={`/enquiry/${courseDetail?._id}`}
            style={{
              backgroundColor: "rgba(106, 115, 250)",
              color: "#fff",
              padding: "15px 38px",
              borderRadius: "6px",
              marginTop: "40px",
            }}
          >
            Join Now
          </Link>
        )} */}
      </div>
    </>
  );
};

export default CourseDetail;
