import { SET_FITNESS_CENTERS } from "../actions/types";

const initialState = {
  fitnesscenters: null,
};

export default function fitnessCenter(state = initialState, action) {
  switch (action.type) {
    case SET_FITNESS_CENTERS:
      return {
        ...state,
        fitnesscenters: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
}
