import React, { useState, useEffect } from "react";
import { getAllFitnessCenterDetailService } from "../../services/fitness-center";
import { Link, useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import styles from "../blogSingle/blogSingle.module.css";
import moment from "moment";
const FitnessCenterDetail = () => {
  const [fitnessCenterDetail, setFitnessCenterDetail] = useState(null);
  const params = useParams();
  const [loader, setLoader] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const onGetFitnessCenterDetail = async () => {
    try {
      const filterQuery = params.id;
      let res = await getAllFitnessCenterDetailService(filterQuery);
      if (res.data.fitnesscenter) {
        setFitnessCenterDetail(res.data.fitnesscenter);
        setLoader(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    onGetFitnessCenterDetail();
  }, []);
  return (
    <>
      <div
        className={`${styles["hero-section"]} container margin-sections `}
        data-aos="zoom-out-down"
      >
        <p className="paragraph">
          Posted on:{" "}
          {moment(fitnessCenterDetail?.fitnessCenterSince).format("DD-MM-YYYY")}
        </p>
        <h2>{fitnessCenterDetail?.fitnessCenterName}</h2>
        <div>
          {/* <h4>Fitness</h4> */}
          <div className={styles.small_image}>
            <img src={fitnessCenterDetail?.profileImage} alt="" />
            <p>
              {/* {fitnessCenterDetail?.instructoreName +
                " - " + */}
              Since {fitnessCenterDetail?.fitnessCenterSince}{" "}
            </p>
          </div>
        </div>
        <img
          src={fitnessCenterDetail?.profileImage}
          alt="Program to Improve Your Weakness"
        />
        <div>
          <div>
            <p>City</p>
          </div>
          <div>
            <p>
              {fitnessCenterDetail?.fitnessCenterCity +
                ", " +
                fitnessCenterDetail?.fitnessCenterState}
            </p>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <p style={{ marginBottom: "20px" }}>Bio</p>
            <p>{fitnessCenterDetail?.fitnessCenterBio}</p>
          </div>
        </div>
        <div>
          <div>
            {/* <Link
              className="mt-4"
              to={`/enquiry/${fitnessCenterDetail._id}`}
              style={{
                backgroundColor: "rgba(106, 115, 250)",
                color: "#fff",
                padding: "10px 18px",
                borderRadius: "10px",
              }}
            >
              Join Now
            </Link> */}
          </div>
          {/* <div>
            <p>{fitnessCenterDetail?.totalBatches}</p>
          </div> */}
        </div>
        {/* <div>
          <div>
            <p>Duration</p>
          </div>
          <div>
            <p>{fitnessCenterDetail?.duration}</p>
          </div>
        </div> */}
        {/* <div>
          <div>
            <p style={{ marginBottom: 30, textDecoration: "underline" }}>
              Details of Course
            </p>
            <p>{fitnessCenterDetail?.detail}</p>
          </div>
        </div> */}
        <ReactPlayer
          url={fitnessCenterDetail?.youTubeUrl}
          playIcon={true}
          controls={true}
          width={"1100px"}
        />

        {/* {user ? ( */}
        <Link
          className="mt-4"
          to={`/enquiry/${fitnessCenterDetail?.id}`}
          style={{
            backgroundColor: "rgba(106, 115, 250)",
            color: "#fff",
            padding: "15px 38px",
            borderRadius: "6px",
            marginTop: "40px",
          }}
        >
          Join Now
        </Link>
        {/* ) : (
          <Link
            className="mt-4"
            to={`/payment-page`}
            style={{
              backgroundColor: "rgba(106, 115, 250)",
              color: "#fff",
              padding: "15px 38px",
              borderRadius: "6px",
              marginTop: "40px",
            }}
          >
            Join Now
          </Link>
        )} */}
      </div>
    </>
  );
};

export default FitnessCenterDetail;
