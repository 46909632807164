// auth flow
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_USERS = "SET_USERS";
export const REMOVE_CURRENT_USER = "REMOVE_CURRENT_USER";
export const SET_STAFF_USERS = "SET_STAFF_USERS";
export const SET_PROFILE = "SET_PROFILE";
export const SET_DASHBOARD_CONTENT = "SET_DASHBOARD_CONTENT";
export const SET_NEW_PASSWORD = "SET_NEW_PASSWORD";
export const SET_TOKEN_EXPIRED = "SET_TOKEN_EXPIRED";
export const SET_CURRENT_LOCATION = "SET_CURRENT_LOCATION";
export const SET_ATTENDANCE = "SET_ATTENDANCE";
export const ADD_OFFER = "ADD_OFFER";
export const SET_ADMIN_STAFF_USERS = "SET_ADMIN_STAFF_USERS";
export const SET_ALL_CUSTOMER = "SET_ALL_CUSTOMER";
export const SET_JOBS = "SET_JOBS";
export const SET_POSTS = "SET_POSTS";
// organisation
// types
export const SET_ORGANISATION = "SET_ORGANISATION";
export const SET_ALL_USERS = "SET_ALL_USERS";
export const SET_TRAINERS = "SET_TRAINERS";
export const SET_FITNESS_CENTERS = "SET_FITNESS_CENTERS";
// workout
export const SET_WORKOUT = "SET_WORKOUT";
// holiday types
export const SET_HOLIDAYS = "SET_HOLIDAYS";
// theme auth types old
export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";
export const NAVTOGGLE = "NAVTOGGLE";
// diet plans
export const SET_DIET_PLAN = "SET_DIET_PLAN";
// event api
export const SET_EVENTS = "SET_EVENTS";

// finance heads and manage finaces types
export const SET_FINANCE_CATEGORY = "SET_FINANCE_CATEGORY";
export const SET_SEARCH_FINANCE_CATEGORIES = "SET_FINANCE_CATEGORY";
// finance entries types
export const SET_FINANCE_ENTRIES = "SET_FINANCE_ENTRIES";
export const SET_SEARCH_FINANCE_ENTRIES = "SET_SEARCH_FINANCE_ENTRIES";
export const SET_PAYMENT_REQUEST = "SET_PAYMENT_REQUEST";
export const SET_PURCHASE_REQUEST = "SET_PURCHASE_REQUEST";
// courses types
export const SET_COURSES = "SET_COURSES";
// post types
export const CREATE_POST_ACTION = "[Post Action] Create Post";
export const CONFIRMED_CREATE_POST_ACTION =
  "[Post Action] Confirmed Create Post";
export const GET_POSTS = "[Post Action] Get Posts";
export const CONFIRMED_GET_POSTS = "[Post Action] Confirmed Get Posts";
export const EDIT_POST_ACTION = "[Post Action] Edit Post";
export const CONFIRMED_EDIT_POST_ACTION = "[Post Action] Confirmed Edit Post";
export const CONFIRMED_DELETE_POST_ACTION =
  "[Post Action] Confirmed Delete Post";
