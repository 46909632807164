import axios from "axios";
export const API_KEY = "AIzaSyCrQ-UOtWmJBbJK7xFBF5K6XB_JB5jGOS4";

const publicReq = axios.create({
  // baseURL: process.env.NODE_ENV === 'development' ? "http://localhost:5000/api" : "https://netplus-backend-4ai2.vercel.app/api",
  baseURL: "https://api.ready2gym.com/api",
  // process.env.NODE_ENV === "development"
  //   ? "http://localhost:5000/api"
  //   : "https://api.ready2gym.com/api",
});

const privateReq = axios.create({
  baseURL: "https://api.ready2gym.com/api",
  // process.env.NODE_ENV === "development"
  //   ? "http://localhost:5000/api"
  //   : "https://api.ready2gym.com/api",
  // baseURL: process.env.NODE_ENV === 'development' ? "http://localhost:5000/api" : "https://netplus-backend-4ai2.vercel.app/api",
});
const imageReq = axios.create({
  baseURL: "https://api.ready2gym.com/api",
  // process.env.NODE_ENV === "development"
  //   ? "http://localhost:5000/api"
  // : "https://api.ready2gym.com/api",
});
privateReq.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export { privateReq, imageReq };
export default publicReq;
