import {
  SET_CURRENT_USER,
  REMOVE_CURRENT_USER,
  SET_PROFILE,
  SET_NEW_PASSWORD,
  SET_TOKEN_EXPIRED,
  SET_USERS,
  SET_STAFF_USERS,
  SET_CURRENT_LOCATION,
  SET_ATTENDANCE,
  SET_DASHBOARD_CONTENT,
  SET_ADMIN_STAFF_USERS,
  SET_ALL_CUSTOMER,
  // LOADING_TOGGLE_ACTION,
  // LOGIN_CONFIRMED_ACTION,
  // LOGIN_FAILED_ACTION,
  // LOGOUT_ACTION,
  // SIGNUP_CONFIRMED_ACTION,
  // SIGNUP_FAILED_ACTION,
} from "../actions/types";

const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  newPassword: "",
  staff: null,
  customers: null,
  adminStaff: [],
  attendance: [],
  tokenExpired: false,
  dashboard: {},
  auth: {
    email: "",
    idToken: "",
    localId: "",
    expiresIn: "",
    refreshToken: "",
  },
  errorMessage: "",
  successMessage: "",
  showLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_CURRENT_LOCATION:
      return {
        ...state,
        location: action.payload,
      };
    case SET_DASHBOARD_CONTENT:
      return {
        ...state,
        dashboard: action.payload,
      };
    case REMOVE_CURRENT_USER:
      return {
        ...state,
        user: null,
        token: null,
        isAuthenticated: false,
        tokenExpired: false,
      };
    case SET_ATTENDANCE:
      return {
        ...state,
        attendance: action.payload,
      };

    case SET_NEW_PASSWORD:
      return {
        ...state,
        newPassword: action.payload,
      };
    case SET_TOKEN_EXPIRED:
      return {
        ...state,
        tokenExpired: action.payload,
      };
    case SET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case SET_STAFF_USERS:
      return {
        ...state,
        staff: action.payload,
      };
    case SET_ALL_CUSTOMER:
      return {
        ...state,
        customers: action.payload,
      };
    case SET_ADMIN_STAFF_USERS:
      return {
        ...state,
        adminStaff: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
