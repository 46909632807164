import { SET_TRAINERS } from "./types";
import { getAllTrainerService } from "../../services/user";

export const getAllTrainers = () => async (dispatch) => {
  try {
    const res = await getAllTrainerService();
    dispatch({
      type: SET_TRAINERS,
      payload: res.data.trainers,
    });
  } catch (e) {
    console.log(e);
  }
};
