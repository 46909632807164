import { SET_COURSES } from "../actions/types";

const initialState = {
  courses: null,
};

export default function fitnessCenter(state = initialState, action) {
  switch (action.type) {
    case SET_COURSES:
      return {
        ...state,
        courses: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
}
