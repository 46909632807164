import React from "react";
import logo from "../../assets/logodark.png";
import { useNavigate } from "react-router-dom";
import styles from "./contact.module.css";
import ContactInfoComponent from "../contact/ContactInfo";
const ContactInfo = () => {
  const navigate = useNavigate();

  return (
    <div
      className="container"
      style={{ paddingBottom: "25px", marginTop: "175px" }}
    >
      <ContactInfoComponent />
      {/* <div className={styles.logo_holder} onClick={() => navigate("/")}>
        <div className={styles.image_holder}>
          <img src={logo} alt="Logo" style={{ height: 175, width: 175 }} />
        </div>
      </div>

      <h2
        className="text-center mt-5 mb-5"
        style={{ color: "#6a73fa", textAlign: "center", marginBottom: "20px" }}
      >
        Contact Us
      </h2>
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "block",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <p>
            <strong style={{ textCenter: "center", fontSize: "16px" }}>
              {" "}
              Address and Email{" "}
            </strong>
          </p>
        </div>

        <div style={{ textAlign: "center" }}>
          <p style={{ fontSize: "20px" }}>
            {" "}
            P. D. And Sons <br />
            <i
              class="fa-solid fa-location-dot"
              style={{ fontSize: "25px" }}
            ></i>{" "}
            Global City,
            <br />
            Sector-124, Kharar,
            <br /> Mohali,
            <br /> Punjab-140301 <br />
            <p>
              <i class="fa-solid fa-envelope" style={{ fontSize: "25px" }}></i>{" "}
              infoready2gym@gmail.com
            </p>
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default ContactInfo;
