import { SET_POSTS } from "./types";
import { getAllPostsListService } from "../../services/post";

export const getAllPostsAction = () => async (dispatch) => {
  try {
    const res = await getAllPostsListService();
    dispatch({
      type: SET_POSTS,
      payload: res.data.post,
    });
  } catch (e) {
    console.log(e);
  }
};
