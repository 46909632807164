import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAllFitnessCenters } from "../../store/actions/fitnesscenter";
import { useSelector, useDispatch } from "react-redux";
import styles from "./fitness.module.css";

const FitnessTraining = () => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fitnessCenters = useSelector(
    (state) => state.fitnesscenter.fitnesscenters
  );
  useEffect(() => {
    if (search.length > 2) {
      dispatch(getAllFitnessCenters(search));
    } else if (search.length == 0) {
      dispatch(getAllFitnessCenters());
    }
  }, [search]);
  return (
    <div className={`${styles["fitness-training"]} container sections-padding`}>
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          marginBottom: "15px",
          padding: "10px, 20px",
        }}
      >
        <input
          style={{
            padding: "12px 120px",
            borderRadius: "10px",
            textAlign: "start",
          }}
          type="text"
          placeholder="Search by city or name"
          value={search}
          name="search"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className={styles["section-head"]}>
        {/* <div>
      
          <h4>Fitness Centers Near You</h4>
        </div> */}

        <div>
          <p className="paragraph">Fitness Centers</p>
          {/* <h2>Upcoming Classes</h2> */}
        </div>
        <div>
          <button onClick={() => navigate("/explore")}>View All</button>
        </div>
      </div>
      <div className={styles["boxes-container"]}>
        {fitnessCenters &&
          [...fitnessCenters].slice(0, 8).map((el) => {
            return (
              <div
                key={el.id}
                data-aos="fade-right"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                <img
                  src={el.profileImage}
                  alt="women in gym"
                  style={{ height: 200, width: 200 }}
                />
                <h3>{el.fitnessCenterName}</h3>
                <p>{el.fitnessCenterAddress} </p>
                <Link
                  className="mt-4"
                  to={`/fitness-center-detail/${el._id}`}
                  style={{
                    backgroundColor: "rgba(106, 115, 250)",
                    color: "#fff",
                    padding: "10px 18px",
                    borderRadius: "10px",
                  }}
                >
                  Read More
                </Link>
                {/* <Link to={el.to}>Join Now</Link> */}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default FitnessTraining;
