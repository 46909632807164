import { SET_WORKOUT } from "../actions/types";

const initialState = {
  workouts: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_WORKOUT:
      return {
        ...state,
        workouts: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
}
