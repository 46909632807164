import { SET_JOBS } from "../actions/types";

const initialState = {
  jobs: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_JOBS:
      return {
        ...state,
        jobs: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
}
