import React, { Suspense, useEffect, useCallback } from "react";
import { Route, Routes } from "react-router";
import { useDispatch } from "react-redux";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Home from "./components/home/Home";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";
import { getAllFitnessCenters } from "./store/actions/fitnesscenter";
import { getAllTrainers } from "./store/actions/trainer";
import CourseDetail from "./components/classes/CourseDetail";
import ExploreCenters from "./components/fitnessTraining/ExploreCenters";
import FitnessCenterDetail from "./components/fitnessTraining/Detail";
import SignUp from "./components/register";
import Terms from "./components/terms";
import Refund from "./components/refund";
import Privacy from "./components/privacy";
import ContactInfo from "./components/contactinfo";
import JobList from "./components/jobs/List";
import JobDetail from "./components/jobs/Detail";
const About = React.lazy(() => import("./components/about/About"));
const Classes = React.lazy(() => import("./components/classes/Classes"));
const YogaClass = React.lazy(() => import("./components/yogaClass/YogaClass"));
const TrainersPage = React.lazy(() =>
  import("./components/trainersPage/TrainersPage")
);
const TrainerSingle = React.lazy(() =>
  import("./components/trainerSingle/TrainerSingle")
);
const Blog = React.lazy(() => import("./components/blog/Blog"));
const BlogSingle = React.lazy(() =>
  import("./components/blogSingle/BlogSingle")
);
const Contact = React.lazy(() => import("./components/contact/Contact"));
const Schedule = React.lazy(() => import("./components/schedule/Schedule"));
const Calculator = React.lazy(() =>
  import("./components/calculator/Calculator")
);
const ErrorPage = React.lazy(() => import("./components/errorPage/ErrorPage"));

const RootStack = () => {
  const dispatch = useDispatch();

  const onGetAPIS = useCallback(() => {
    dispatch(getAllFitnessCenters());
    dispatch(getAllTrainers());
  }, [dispatch]);

  useEffect(() => {
    onGetAPIS();
  }, []);

  return (
    <div>
      <Routes>
        <Route path="register" element={<SignUp />} />
      </Routes>
      <Header />
      <ScrollToTop />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="register" element={<SignUp />} />
          <Route path="about" element={<About />} />
          <Route path="courses" element={<Classes />} />
          <Route path="yogaClass" element={<YogaClass />} />
          <Route path="trainers" element={<TrainersPage />} />
          <Route path="singleClass" element={<TrainerSingle />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blog-detail/:id" element={<BlogSingle />} />
          <Route path="enquiry/:id" element={<Contact />} />
          {/* <Route path="enquiry/" element={<Contact />} /> */}
          <Route path="explore" element={<ExploreCenters />} />
          <Route
            path="fitness-center-detail/:id"
            element={<FitnessCenterDetail />}
          />
          <Route path="job-list" element={<JobList />} />
          <Route path="job-detail/:id" element={<JobDetail />} />

          <Route path="terms-of-use" element={<Terms />} />
          <Route path="refund-policy" element={<Refund />} />
          <Route path="privacy-policy" element={<Privacy />} />
          <Route path="contact" element={<ContactInfo />} />

          <Route path="course-detail/:id" element={<CourseDetail />} />
          <Route path="schedule" element={<Schedule />} />
          <Route path="calculator" element={<Calculator />} />
          <Route path="errorPage" element={<ErrorPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Suspense>
      <Footer />
    </div>
  );
};

export default RootStack;
